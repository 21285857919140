import * as React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Header from "./Components/Header/Header";
import AboutUs from "./Components/AboutUs.tsx/AboutUs";
import { Gallery } from "./Components/Gallery/Gallery";
import { ContactUs } from "./Components/ContactUs/ContactUs";

const colors = {
    brand: {
        navy: "#0F2D49",
        light: "#E9E9E9",
        gold: "#CCBF90",
    },
};

const theme = extendTheme({
    colors,
    fonts: {
        body: "Montserrat, sans-serif",
        heading: "Montserrat, sans-serif",
    },
});

export const App = () => (
    <ChakraProvider theme={theme}>
        <Header />
        <AboutUs />
        <Gallery />
        <ContactUs />
    </ChakraProvider>
);
