import { Button } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { EmailIcon, Icon, PhoneIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import {
    Box,
    Center,
    Flex,
    Link,
    Text,
    VStack,
    Wrap,
    WrapItem,
} from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import axios from "axios";
import { useState } from "react";
import { AiFillInstagram } from "react-icons/ai";

export function ContactUs() {
    const [emailInput, setEmailInput] = useState("");
    const [phoneInput, setPhoneInput] = useState("");
    const [nameInput, setNameInput] = useState("");
    const [msgInput, setMsgInput] = useState("");
    const [contactLoading, setContactLoading] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const emailError = emailInput === "";
    const nameError = nameInput === "";
    const msgError = msgInput === "";
    const phoneError = phoneInput === "";
    const disableButton = emailError || nameError || phoneError || msgError;
    const trySend = () => {
        if (!disableButton) {
            setContactLoading(true);
            try {
                axios.post(
                    "https://uzzq40wb08.execute-api.eu-west-2.amazonaws.com/Prod/contact-us",
                    {
                        email: emailInput,
                        phone: phoneInput,
                        name: nameInput,
                        msg: msgInput,
                    }
                );
            } catch (error) {
                console.log("failed to send email", error);
            } finally {
                setContactLoading(false);
                setMessageSent(true);
            }
        }
    };

    return (
        <Box
            id="contact"
            background="brand.navy"
            py="2rem"
            px={{ base: "0.5rem", md: "1rem" }}
        >
            <Center>
                <Text as="h1" fontSize={"2rem"} color="brand.gold" pb="1rem">
                    CONTACT US
                </Text>
            </Center>
            <Center>
                <Flex>
                    <Box p={4}>
                        <Wrap spacing="2rem" justify={"center"}>
                            <WrapItem maxWidth="300px">
                                <Box>
                                    <Text
                                        mt={{ sm: 3, md: 3, lg: 5 }}
                                        color="brand.light"
                                    >
                                        Please fill in the following form or
                                        contact us using the options below:
                                    </Text>
                                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                                        <VStack
                                            pl={0}
                                            spacing={3}
                                            alignItems="flex-start"
                                        >
                                            <Text
                                                color={useColorModeValue(
                                                    "brand.light",
                                                    "brand.navy"
                                                )}
                                            >
                                                <PhoneIcon mr="5px" />
                                                <Link href="tel:02080584902">
                                                    0208 058 4902
                                                </Link>
                                            </Text>
                                            <Text
                                                color={useColorModeValue(
                                                    "brand.light",
                                                    "brand.navy"
                                                )}
                                            >
                                                <EmailIcon mr="5px" />
                                                <Link href="mailto:info@abbeywoods.co.uk">
                                                    info@abbeywoods.co.uk
                                                </Link>
                                            </Text>
                                            <Text
                                                color={useColorModeValue(
                                                    "brand.light",
                                                    "brand.navy"
                                                )}
                                            >
                                                <Icon
                                                    as={AiFillInstagram}
                                                    mr="5px"
                                                    verticalAlign={"middle"}
                                                />
                                                <Link
                                                    href="https://www.instagram.com/abbeywooddevelopment/"
                                                    target="_blank"
                                                >
                                                    Instagram
                                                </Link>
                                            </Text>
                                        </VStack>
                                    </Box>
                                </Box>
                            </WrapItem>
                            <WrapItem>
                                <Box
                                    bg="brand.light"
                                    borderRadius="lg"
                                    width="300px"
                                >
                                    <Box p={5}>
                                        <VStack spacing={5}>
                                            {messageSent ? (
                                                <Text color={"brand.navy"}>
                                                    Thanks for getting in
                                                    contact, we'll get back to
                                                    you as soon as possible!
                                                </Text>
                                            ) : (
                                                <>
                                                    <FormControl isRequired>
                                                        <FormLabel>
                                                            Name
                                                        </FormLabel>
                                                        <Input
                                                            borderColor="brand.navy"
                                                            type="text"
                                                            value={nameInput}
                                                            onChange={(e) =>
                                                                setNameInput(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl isRequired>
                                                        <FormLabel>
                                                            Email
                                                        </FormLabel>
                                                        <Input
                                                            borderColor="brand.navy"
                                                            type="email"
                                                            value={emailInput}
                                                            onChange={(e) =>
                                                                setEmailInput(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl isRequired>
                                                        <FormLabel>
                                                            Phone
                                                        </FormLabel>
                                                        <Input
                                                            borderColor="brand.navy"
                                                            type="phone"
                                                            value={phoneInput}
                                                            onChange={(e) =>
                                                                setPhoneInput(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormControl isRequired>
                                                        <FormLabel>
                                                            Message
                                                        </FormLabel>
                                                        <Textarea
                                                            borderColor="brand.navy"
                                                            _hover={{
                                                                borderRadius:
                                                                    "brand.navy",
                                                            }}
                                                            placeholder="message"
                                                            value={msgInput}
                                                            onChange={(e) =>
                                                                setMsgInput(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    </FormControl>
                                                    <Center>
                                                        <FormControl>
                                                            <Button
                                                                isDisabled={
                                                                    disableButton
                                                                }
                                                                variant="solid"
                                                                bg="brand.navy"
                                                                color="brand.gold"
                                                                _hover={{
                                                                    color: "brand.light",
                                                                }}
                                                                isLoading={
                                                                    contactLoading
                                                                }
                                                                onClick={() =>
                                                                    trySend()
                                                                }
                                                            >
                                                                Send Message
                                                            </Button>
                                                        </FormControl>
                                                    </Center>
                                                </>
                                            )}
                                        </VStack>
                                    </Box>
                                </Box>
                            </WrapItem>
                        </Wrap>
                    </Box>
                </Flex>
            </Center>
        </Box>
    );
}
