import { Box, Text, VStack, Center } from "@chakra-ui/layout";

export default function AboutUs() {
    return (
        <Box id="aboutus" background="brand.navy" py="2rem" px="1rem">
            <Center>
                <Box maxW={"xl"}>
                    <Center>
                        <Text
                            as="h1"
                            fontSize={"2rem"}
                            color="brand.gold"
                            pb="1rem"
                        >
                            ABOUT US
                        </Text>
                    </Center>
                    <VStack textAlign={"center"} spacing={"2rem"}>
                        <Text color="brand.light" fontSize={"1.25rem"}>
                            Here at Abbeywood Development, we specialise in the
                            development and renovation of homes and properties
                            to the highest standard. Founded in 2016, we are a
                            dynamic, family-run company based in the North
                            London and Hertfordshire area with over 50 years
                            worth of knowledge and experience in the industry.
                        </Text>
                        <Text color="brand.light" fontSize={"1.25rem"}>
                            We pride ourselves on having established a culture
                            based on honesty, reliability and trust. We
                            continuously strive to deliver an unrivalled quality
                            of service and workmanship in all that we do. We
                            offer a variety of bespoke, on-trend, creative
                            solutions to suit any style of home or project and
                            pride ourselves on our ability to cater to any
                            requirement.
                        </Text>
                    </VStack>
                </Box>
            </Center>
        </Box>
    );
}
