import { Image } from "@chakra-ui/image";
import { Box, Center, Text, Wrap, WrapItem } from "@chakra-ui/layout";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

type numnum = { [key: number]: number };
const folderSize: numnum = {
    1: 36,
    2: 12,
    3: 13,
    4: 4,
    5: 5,
    6: 7,
};

export function Gallery() {
    const projIds = [1, 2, 3, 4, 5, 6];
    return (
        <Box
            id="gallery"
            background="brand.light"
            py="2rem"
            px={{ base: "0.5rem", md: "1rem" }}
        >
            <Center>
                <Text as="h1" fontSize={"2rem"} color="brand.navy" pb="1rem">
                    GALLERY
                </Text>
            </Center>

            <Center>
                <Wrap spacing="2rem" justify={"center"}>
                    {projIds.map((id) => (
                        <WrapItem>
                            <Box
                                m="1rem"
                                width={{ base: "325px", md: "500px" }}
                                height={{ base: "300px", md: "400px" }}
                                boxShadow={
                                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;"
                                }
                            >
                                <ImageSlide projId={id} />
                            </Box>
                        </WrapItem>
                    ))}
                </Wrap>
            </Center>
        </Box>
    );
}

const ImageSlide = ({ projId }: { projId: number }) => {
    const size = folderSize[projId];
    return (
        <Carousel infiniteLoop showIndicators={false} showThumbs={false}>
            {[...Array(size)].map((_, i) => {
                return (
                    <Image
                        src={`projects/${projId}/${i + 1}.jpg`}
                        width={{ base: "325px", md: "500px" }}
                        height={{ base: "300px", md: "400px" }}
                        objectFit="cover"
                    />
                );
            })}
        </Carousel>
    );
};
