"use client";

import {
    Box,
    Flex,
    Text,
    IconButton,
    Stack,
    Collapse,
    useColorModeValue,
    useDisclosure,
    Image,
    Divider,
    Link,
} from "@chakra-ui/react";
import {
    HamburgerIcon,
    CloseIcon,
    PhoneIcon,
    EmailIcon,
} from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import { AiFillInstagram } from "react-icons/ai";

export default function Header() {
    const { isOpen, onToggle } = useDisclosure();
    const [height, setHeight] = useState<any>(0);
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && ref.current["clientHeight"] !== null) {
            setHeight(ref.current["clientHeight"] ?? 0);
        }
    }, []);
    return (
        <>
            <Box ref={ref} id="home">
                <Flex
                    bg={useColorModeValue("brand.navy", "brand.light")}
                    color={useColorModeValue("brand.light", "brand.navy")}
                    minH={"60px"}
                    p={{ base: 4 }}
                    borderBottom={1}
                    borderStyle={"solid"}
                    borderColor={useColorModeValue("brand.navy", "brand.light")}
                    align={"center"}
                >
                    <Flex
                        flex={{ base: 1, md: "auto" }}
                        ml={{ base: -2 }}
                        display={{ base: "flex", md: "none" }}
                    >
                        <IconButton
                            onClick={onToggle}
                            icon={
                                isOpen ? (
                                    <CloseIcon w={3} h={3} />
                                ) : (
                                    <HamburgerIcon w={5} h={5} />
                                )
                            }
                            variant={"ghost"}
                            background={useColorModeValue(
                                "brand.navy",
                                "brand.light"
                            )}
                            _hover={{
                                background: useColorModeValue(
                                    "brand.navy",
                                    "brand.light"
                                ),
                            }}
                            _active={{ background: "transparent" }}
                            color={useColorModeValue(
                                "brand.light",
                                "brand.navy"
                            )}
                            borderColor={useColorModeValue(
                                "brand.light",
                                "brand.navy"
                            )}
                            aria-label={"Toggle Navigation"}
                        />
                    </Flex>
                    <Stack>
                        <Flex justify={"center"}>
                            <Image
                                src="Original.svg"
                                width={{ base: "80%", md: "40%", lg: "30%" }}
                                pt="8px"
                            />
                        </Flex>
                        <Flex
                            flex={{ base: 1 }}
                            justify={{ base: "center", md: "center" }}
                        >
                            <Flex display={{ base: "none", md: "flex" }}>
                                <DesktopNav />
                            </Flex>
                        </Flex>
                    </Stack>
                </Flex>

                <Collapse in={isOpen} animateOpacity>
                    <MobileNav />
                </Collapse>

                <Stack
                    flex={{ base: 1, md: 0 }}
                    justify={"flex-end"}
                    direction={"row"}
                    spacing={4}
                    position={"absolute"}
                    top={"0"}
                    right={"0"}
                    border={"0"}
                    p="16px"
                    display={{ base: "none", lg: "flex" }}
                >
                    <Text
                        color={useColorModeValue("brand.light", "brand.navy")}
                    >
                        <PhoneIcon mr="5px" />
                        <Link href="tel:02080584902" verticalAlign={"middle"}>
                            0208 058 4902
                        </Link>
                    </Text>
                    <Text
                        color={useColorModeValue("brand.light", "brand.navy")}
                    >
                        <EmailIcon mr="5px" />
                        <Link href="mailto:info@abbeywoods.co.uk">
                            info@abbeywoods.co.uk
                        </Link>
                    </Text>
                    <IconButton
                        size="sm"
                        minWidth="14px"
                        height="14px"
                        variant="ghost"
                        color={useColorModeValue("brand.light", "brand.navy")}
                        _hover={{ background: "transparent" }}
                        onClick={() =>
                            window.open(
                                "https://www.instagram.com/abbeywooddevelopment/",
                                "_blank"
                            )
                        }
                        icon={<AiFillInstagram />}
                        aria-label={`Go to instagram`}
                        verticalAlign={"middle"}
                        mt="5px"
                    />
                </Stack>
            </Box>
            <Image
                width={"100%"}
                height={`calc(100vh - ${height}px)`}
                objectFit="cover"
                src="IMG_7496.JPG"
            ></Image>
        </>
    );
}

export function scroll(id: string) {
    const section = document.querySelector(id);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
}

const DesktopNav = () => {
    const linkColor = useColorModeValue("brand.light", "brand.navy");
    const linkHoverColor = useColorModeValue("white", "white");

    return (
        <Stack
            direction={"row"}
            spacing={{ base: "2rem", lg: "6rem" }}
            mt={"2rem"}
        >
            {NAV_ITEMS.map((navItem, i) => (
                <>
                    <Box key={i}>
                        <Box
                            as="button"
                            // href={navItem.href ?? "#"}
                            onClick={() => scroll(navItem.href ?? "#")}
                            fontSize={"1.25rem"}
                            fontWeight={500}
                            color={linkColor}
                            style={{ transition: "color 0.4s ease-out" }}
                            _hover={{
                                textDecoration: "none",
                                color: linkHoverColor,
                            }}
                            key={i}
                        >
                            {navItem.label}
                        </Box>
                    </Box>
                    {i !== NAV_ITEMS.length - 1 && <RotatingDivider />}
                </>
            ))}
        </Stack>
    );
};

const RotatingDivider = () => {
    const [hover, setHover] = useState(false);
    return (
        <Box
            px="5px"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setTimeout(() => setHover(false), 700)}
        >
            <Divider
                orientation="vertical"
                transition={"transform .7s ease-in-out;"}
                transform={hover ? "rotate(360deg)" : "none"}
            />
        </Box>
    );
};

const MobileNav = () => {
    return (
        <Stack
            bg={useColorModeValue("brand.navy", "brand.navy")}
            p={4}
            display={{ md: "none" }}
        >
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, href }: NavItem) => {
    return (
        <Stack spacing={4}>
            <Box
                py={2}
                as="button"
                onClick={() => scroll(href ?? "#")}
                justifyContent="space-between"
                alignItems="center"
                _hover={{
                    textDecoration: "none",
                }}
            >
                <Text
                    fontWeight={500}
                    color={useColorModeValue("brand.light", "brand.light")}
                >
                    {label}
                </Text>
            </Box>
        </Stack>
    );
};

interface NavItem {
    label: string;
    subLabel?: string;
    children?: Array<NavItem>;
    href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
    {
        label: "HOME",
        href: "#home",
    },
    {
        label: "ABOUT US",
        href: "#aboutus",
    },
    {
        label: "GALLERY",
        href: "#gallery",
    },
    {
        label: "CONTACT",
        href: "#contact",
    },
];
